import {EnumShipmentType} from "../../helper/Enums";

const INITIAL_STATE = {
    shipmentGroupsRetail: {
        current_page: 0,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: null,
        to: 0,
        total: 0
    },
    shipmentGroupsCorporate: {
        current_page: 0,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: null,
        to: 0,
        total: 0
    },
    shipmentGroupsLoadingRetail: true,
    shipmentGroupsLoadingCorporate: true,
}
export default (state = INITIAL_STATE, action) => {

    if (action.type === 'SET_SHIPMENT_GROUPS') {
        if (action.payload.enum_type === EnumShipmentType.RETAIL) {
            return {...state, shipmentGroupsRetail: action.payload.shipment_groups}
        }
        if (action.payload.enum_type === EnumShipmentType.CORPORATE) {
            return {...state, shipmentGroupsCorporate: action.payload.shipment_groups}
        }
    } else if (action.type === 'SET_SHIPMENT_GROUPS_LOADING') {

        if (action.payload.enum_type === EnumShipmentType.RETAIL) {
            return {...state, shipmentGroupsLoadingRetail: action.payload.loading}
        }
        if (action.payload.enum_type === EnumShipmentType.CORPORATE) {
            return {...state, shipmentGroupsLoadingCorporate: action.payload.loading}
        }
    } else {
        return state;
    }
}