import firebase from 'firebase';
import api from "./services/api";
import {checkLogout401} from "./services/auth";
export const TOKEN_PUSH = "@Logistica-Token-Push";

export const inicializarFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyACXUnpmosO1tdJmeMV_Oa23VZ5KH_XfG0",
        authDomain: "webpush-238822.firebaseapp.com",
        databaseURL: "https://webpush-238822.firebaseio.com",
        projectId: "webpush-238822",
        storageBucket: "webpush-238822.appspot.com",
        messagingSenderId: "15429670988",
        appId: "1:15429670988:web:ed1957f378ee1fb9"
    });
}

export const askPermissionWebPush = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();

        let tokenStorage = localStorage.getItem(TOKEN_PUSH)

        if(tokenStorage !== token) {

            api.post(`v1/user/notification`, {"token": token})
                .then(response => {
                    console.log(response);
                    localStorage.setItem(TOKEN_PUSH, token);
                })
                .catch((error) => {
                    console.error(error);
                    checkLogout401(error);
                    return 0;
                })
        }
        return token;
    } catch (error) {
        console.error(error);
    }
}
