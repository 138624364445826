import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import './App.scss';
import Loader from "react-loader-spinner";

import ShipmentGroupsReducer from "./redux/reducers/ShipmentGroupsReducer";
import ExpeditionListingReducer from "./redux/reducers/ExpeditionListingReducer";
import NavigationReducer from "./redux/reducers/NavigationReducer";

const loading = () => <div
    style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: "40%",
    }}
>
  <Loader type="Bars" color="#2BAD60" height="50" width="100"/>
</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const rootReducer = combineReducers({
    ShipmentGroupsReducer: ShipmentGroupsReducer,
    ExpeditionListingReducer: ExpeditionListingReducer,
    NavigationReducer: NavigationReducer
});
const store = createStore(rootReducer, {}, applyMiddleware(ReduxThunk));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
              <Provider store={store}>
                    <Switch>
                      <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                      <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                      <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                    </Switch>
              </Provider>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
