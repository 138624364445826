const INITIAL_STATE = {
    items: []
}
export default (state = INITIAL_STATE, action) => {
    if (action.type === 'SET_PENDING') {
        let items = state.items;
        if(action.payload.total > 0) {
            items.filter(element => (element.url === '/expedition')).forEach(function (item) {
                item.children.filter(element => (element.url === '/expedition/pending')).forEach(function (item) {
                    item.badge = {
                        variant: 'danger',
                        text: action.payload.total,
                    };
                })
            })
        } else {
            items.filter(element => (element.url === '/expedition')).forEach(function (item) {
                item.children.filter(element => (element.url === '/expedition/pending')).forEach(function (item) {
                    item.badge = {};
                })
            })
        }
        return {...state, items: items}
    }
    if(action.type === 'SET_NAVIGATION') {
        return {...state, items: action.payload}
    }
    return state;
}
