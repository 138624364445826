export const TOKEN_KEY = "@Logistica-Token";
export const NAME = "@Logistica-Name";
export const EMAIL = "@Logistica-Email";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getName = () => localStorage.getItem(NAME);
export const getEmail = () => localStorage.getItem(EMAIL);

export const saveLogin = (token, name, email) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(NAME, name);
    localStorage.setItem(EMAIL, email);
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(NAME);
    localStorage.removeItem(EMAIL);
};

export const checkLogout401 = (error) => {
    if (error.toString().includes('code 401')) {
        logout();
    }
};


