const INITIAL_STATE = {
    expeditionListing: {
        current_page: 0,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: null,
        to: 0,
        total: 0
    }
}
export default (state = INITIAL_STATE, action) => {

    if (action.type === 'SET_EXPEDITION_LISTING') {

        return {...state, expeditionListing: action.payload.shipment_groups}

    } else {
        return state;
    }
}